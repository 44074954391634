<template>
  <div id="YearGallery">
    <app-navbar></app-navbar>
    <div class="yellowTwoBallonHorizont">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/yellowTwoBallonHorizont.png"
           alt="yellowTwoBallonHorizont">
    </div>
    <div class="crossBalloneHalf">
      <img v-parallax="0.3" class="img-fluid" src="@/assets/crossBalloneHalf.png" alt="crossBalloneHalf">
    </div>
    <div class="container">
      <h1 class="text-center">{{ "Gallery.label" | staticTranslation }}</h1>
      <div class="years">
        <a v-on:click="selectedPeriodId = null">{{ "Gallery.all" | staticTranslation }}</a>
        <a v-bind:key="period.id" v-on:click="selectedPeriodId = period.id"
           v-for="period in periods">{{ period.name | translation }}</a>
      </div>
      <div class="row no-gutters pb-lg-4">
        <div v-bind:key="album.id" v-for="album in filteredAlbums" class="col-lg-4"><a
            :href="'/gallery/album/' + album.id">
          <h4>{{ album.name | translation }}</h4><img class="img-fluid cropped-img gallery-picture" :src="getUrl(album.id)" alt=""></a>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>
<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import axios from "@/api/axiom-instance";
import config from "@/config";

export default {
  name: "YearGallery",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  },
  data() {
    return {
      albums: [],
      periods: [],
      selectedPeriodId: null
    }
  },
  computed: {
    filteredAlbums: function () {
      return this.albums.filter(album => this.selectedPeriodId == null || album.periodId === this.selectedPeriodId);
    }
  },
  created() {
    axios
        .get("/album/list/visible")
        .then((success) => {
          this.albums = success.data;
          let periodIds = this.albums.map(album => album.periodId);
          axios
              .get("/period/list")
              .then((success) => {
                this.periods = success.data.filter(period => periodIds.includes(period.id))
              }).catch(error => this.error = error)
        }).catch(error => this.error = error)

  },
  methods: {
    getUrl: function (id) {
      return config.API_URL + "/image/preview?type=album-main&name=" + id;
    },
  }
}
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  padding-top: 3em;
  padding-bottom: 1.5em;
  font-family: "Transcript Pro";
}

h4 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  font-size: 2em;
  font-family: 'Transcript Pro';
  position: absolute;
  bottom: 0%;
}

a {
  color: #eae7d600;
}

a:hover {
  color: white;
  /**
  animation: animationTextGallery;
  animation-duration: 2s;
  animation-timing-function: ease-out;
   */
}

@keyframes animationTextGallery {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.gallery-picture:hover {
  box-shadow: 0 3px 8px 0 #1d212470;
}

.cropped-img {
  height: 300px;
  object-fit: cover;
}

#YearGallery {
  background-color: #ED5B2D;
  height: 100vh;
}

.years {
  padding-bottom: 2.5em;
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-family: "Transcript Pro";
}
.years a {
  padding: 5px 20px;
  color: #EAEAEA;
}

.years a:hover {
  color: white;
}
.yellowTwoBallonHorizont {
  position: absolute;
  width: 10%;
  top: 24vh;
  left: 4%;
}
.crossBalloneHalf {
  width: 10%;
  z-index: 3;
  position: absolute;
  text-align: end;
  top: 100vh;
  right: 0%;
}
@media (max-width: 1000px) {
  .yellowTwoBallonHorizont,
  .crossBalloneHalf {
    display: none;
  }
}
</style>
